import React from "react";
import { maintenance } from "config";
import { Routes, Route } from "react-router-dom";
import {
  About,
  Album,
  Contact,
  Error,
  Header,
  Home,
  Maintenance,
} from "containers";

const App = () => (
  <div className="app">
    {maintenance.active ? (
      <Maintenance />
    ) : (
      <>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/:album" element={<Album />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </>
    )}
  </div>
);

export default App;
