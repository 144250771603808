import React from "react";

const Burguer = ({ handleBurguer, showAlbum }) => {
  const toggleMenu = () => handleBurguer(showAlbum);

  return (
    <div
      className={`burguer burguer__arrow burguer--up${
        showAlbum ? " burguer--open" : ""
      }`}
      onClick={toggleMenu}
    >
      <div className="burguer__lines"></div>
    </div>
  );
};

export default Burguer;
