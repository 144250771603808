import React, { useEffect, useState } from "react";
import { home as homeConfig } from "config";
import Page from "components/Page";
import Image from "components/Image";
import getImage from "utils/storage";

const Home = () => {
  const [image, setImage] = useState(null);

  const setImageURL = async () => {
    const url = await getImage(homeConfig.image);
    setImage(url);
  };

  useEffect(() => {
    setImageURL();
  }, []);

  return (
    <Page>
      <Image
        src={image}
        link={homeConfig.link}
        text="This is Home!"
        alt="Home"
      />
    </Page>
  );
};

export default Home;
