import React from "react";
import Page from "components/Page";

const About = () => (
  <Page>
    <p>Photographer based in Porto</p>
  </Page>
);

export default About;
