import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import metas from "content/metas";

const Head = () => {
  const { pathname } = useLocation();
  // eslint-disable-next-line
  const path = pathname.match(/[^\/]*$/)[0];
  const { title, description, image, url } = metas[path] || metas.default;

  return (
    <div className="head">
      <Helmet>
        <title>{`${title ? `${title} | ` : ""}Miguel Pires`}</title>
        <meta name="description" content={description} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="theme-color" content="#000000" />
        <meta charset="utf-8" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="description" content={description} />
        <link rel="canonical" href={`https://www.miguelpires.com/${url}`} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:url"
          content={`https://www.miguelpires.com/${url}`}
        />
        <meta property="og:site_name" content="Miguel Pires" />
        <meta property="og:image" content={image} />
        <meta property="og:image:secure_url" content={image} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:alt" content="Miguel Pires" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={image} />
      </Helmet>
    </div>
  );
};

export default Head;
