import React from "react";
import Page from "components/Page";

const Contact = () => (
  <Page>
    <h2>Collaborations & General Inquires:</h2>
    <p>
      <a
        href="mailto:hello@miguelpires.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        hello@miguelpires.com
      </a>
    </p>
  </Page>
);

export default Contact;
