import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Menu from "containers/Menu";
import logo from 'assets/logo.svg'
import Burguer from "components/Burguer";

const Header = () => {
  const [showAlbum, setShowAlbum] = useState(null);
  const [hideMenu, setHideMenu] = useState(false);

  const handleShowAlbum = (album) => setShowAlbum(album ? album : null);
  const handleBurguer = (value) => setShowAlbum(!value);
  const handleScroll = (e) => setHideMenu(e.deltaY > 0 && window.scrollY > 80);

  useEffect(() => {
    document.addEventListener("mousewheel", handleScroll);
  }, []);

  return (
    <header
      className={`header${hideMenu ? " header--hidden" : ""}${
        showAlbum ? " header--lock header--extended" : ""
      }`}
    >
      <div className="header__logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <Burguer showAlbum={showAlbum} handleBurguer={handleBurguer} />
      <Menu showAlbum={showAlbum} handleShowAlbum={handleShowAlbum} />
    </header>
  );
};

export default Header;
