import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import Config from "config";

const firebaseApp = initializeApp(Config);
const storage = getStorage(firebaseApp);

const getReference = (reference) => {
  return ref(storage, reference);
};

export const getImage = (image) => {
  const reference = getReference(image);
  return getDownloadURL(reference);
};

const getPrefix = async (album) => {
  const reference = getReference(`${album}/`);
  const { prefixes } = await listAll(reference);
  return prefixes[0];
};

const getTitle = async (album) => {
  const { name } = await getPrefix(album);
  return name;
};

const getImages = async (album) => {
  const prefix = await getPrefix(album);
  const { items } = await listAll(prefix);
  const imagesURLs = items.map((item) => getDownloadURL(item));
  const urls = await Promise.all(imagesURLs);
  return urls;
};

export const loadAlbum = (album) =>
  new Promise(async (resolve, reject) => {
    try {
      const title = await getTitle(album);
      const urls = await getImages(album);
      resolve({ title, urls });
    } catch (error) {
      reject(new Error("No album with the reference has been found!"));
    }
  });

export default getImage;
