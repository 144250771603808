import React, { useState } from "react";

const Image = ({ link, disableClick, text, src, alt }) => {
  const [zoom, setZoom] = useState(false);

  const zoomify = (e) => {
    e.preventDefault();
    setZoom(!zoom);
  };

  const addLink = () => (link ? link : "/#");

  const addClass = () => {
    if (disableClick) return "image--disabled";
    else if (zoom) return "image--zoomed";
    else return "";
  };

  const addClick = () => (link ? null : zoomify);

  return src ? (
    <div className={`image ${text ? "image__text" : ""}`}>
      {!disableClick && zoom ? (
        <div className="image__background" onClick={addClick()}></div>
      ) : null}
      <a href={addLink()} className={addClass()} onClick={addClick()}>
        <img src={src} alt={alt} />
        {text ? <h1>{text}</h1> : null}
      </a>
    </div>
  ) : null;
};

export default Image;
