import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { loadAlbum } from "utils/storage";
import Error from "containers/Error";
import Image from "components/Image";

const Album = () => {
  const [title, setTitle] = useState("");
  const [images, setImages] = useState(null);
  const [is404, setIs404] = useState(false);

  const { album } = useParams();

  const displayAlbum = useCallback(async () => {
    try {
      const { title, urls } = await loadAlbum(album);
      setTitle(title);
      setImages(urls);
      setIs404(false);
    } catch (error) {
      console.error(error);
      setIs404(true);
    }
  }, [album]);

  useEffect(() => {
    displayAlbum();
  }, [displayAlbum]);

  return (
    <div className="album">
      {is404 ? (
        <Error />
      ) : (
        <>
          {title && <h1>{title}</h1>}
          {images &&
            images.map((image) => (
              <Image src={image} key={image} alt="Album" />
            ))}
        </>
      )}
    </div>
  );
};

export default Album;
