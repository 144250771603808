import React from "react";
import { Link } from "react-router-dom";
import Page from "components/Page";

const Error = () => (
  <Page>
    <h1>404</h1>
    <p>Page not found</p>
    <p>
      <Link to="/">Go back</Link>
    </p>
  </Page>
);

export default Error;
