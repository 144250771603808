import React from "react";
import menu from "content/menu";
import { Link } from "react-router-dom";

const Menu = ({ handleShowAlbum, showAlbum }) => {
  return (
    <div
      className={`menu${showAlbum ? " menu--extended menu--open" : ""}`}
      onMouseLeave={() => handleShowAlbum()}
    >
      {
        <ul>
          {menu.map(({ albuns, name, link }) =>
            albuns ? (
              <li
                className={`menu__album ${
                  showAlbum === name ? "menu__album--show" : ""
                }`}
                onMouseOver={() => handleShowAlbum(name)}
                key={name}
              >
                <Link to="#">{name}</Link>
                <ul>
                  {albuns.map(({ name, link }) => (
                    <li className="menu__album-link" key={link}>
                      <Link onClick={() => handleShowAlbum()} to={`/${link}`}>
                        {name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            ) : (
              <li className="menu__link" key={link}>
                <Link onClick={() => handleShowAlbum()} to={`/${link}`}>
                  {name}
                </Link>
              </li>
            )
          )}
        </ul>
      }
    </div>
  );
};

export default Menu;
